import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer'
import Layout from '../layouts'

class RefundPolicy extends Component {
    render() {       
        return (
            <Fragment>
                 <Layout>
               <div class="sub-pages-container">
               <div>
                        <h1>Return and Refund Policy

                        </h1>
                    </div>
                    <div>
                        <h3>Products and Services </h3>
                        <p>
                            Fairfest Media Limited offers a range of products and services available on our website,
                            including but not limited to event management services, digital marketing solutions, and
                            custom media products.
                        </p>
                    </div>
                    <div>
                        <h3>Refund Eligibility </h3>
                        <p>
                            Refunds may be considered if services are not rendered or are inadequately rendered.
                            Customers must write to us at contact@fairfest.in within 14 days of payment to request a
                            refund.
                            Each request will be reviewed individually, considering the specific circumstances.
                        </p>

                    </div>
                    <div>
                        <h3>Refund Processing </h3>
                        <p>
                            Refund requests, if approved, will be processed within 10-14 business days.
                            The refunded amount will be credited to the customer’s bank account. </p>

                    </div>
                    <div>
                        <h3>Pricing </h3>
                        <p>
                            All products and services are subject to pricing as listed on our website - ( ).
                             For custom
                            services, pricing will be communicated prior to payment.
                        </p>

                    </div>
                    <div>
                        <h3>Contact Us </h3>
                        <p>
                            For any inquiries, please contact us, 
                            Email: contact@fairfest.in
                            Phone: +91 22 4555 8518 
                            Operating Address: Fairfest Media Limited, 25 C/1, Belvedere Road, Alipur, 
                            Kolkata 700 027, India

                        </p>

                    </div>
                    <div>
                        <h3>Note</h3>
                        <p>
                            This policy is subject to change at the discretion of Fairfest Media Limited. Please refer
                            to our website for the most current policy information.
                        </p>

                    </div>
                </div>
    <Footer />
  </Layout>
  </Fragment>
)
}
}
export default RefundPolicy